import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Login from './components/Login.jsx';
import Navbar from './components/navigation/Navbar.jsx';
import { IsLoggedin, IsNoLoggedin } from './function/fn.js';
import Authorization from './components/pages/Authorization.jsx';

import {
  Container,
} from "@mui/material";
function App() {
  return (
    <div className='App'>
      <BrowserRouter>
        <Routes>
            <Route element={<IsNoLoggedin />}> 
            <Route path="/" element={<Login />} />
            </Route> 
            <Route element={<IsLoggedin />}> 
              <Route
                path="/administrator"
                element={
                  <>
                    <Navbar />
                    <Container maxWidth={false} style={{ marginTop: '80px', marginBottom: '10px'}}>
                      <Authorization />
                    </Container>
                  </>
                }
              />
            </Route> 
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
