import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import '../stylesheets/login.css';
import Loading from "./Loading";
function Login() {
    const [formData, setFormData] = useState({
        username: '',
        password: '',
    });
    const [loading, setLoading] = useState(false);

    const MySwal = withReactContent(Swal);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
        ...formData,
        [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            const response = await axios.post('http://api.elhalcon.com.ni:9096/api/Users/Login/', formData);
            
            localStorage.setItem('token', response.data.token);;
            if (response.status === 200) {
                window.location.href = "/administrator";
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            console.error('Error al conectarse a la API:', error);
            if(error.response.data.error === 'Usuario no existe'){
                MySwal.fire({
                    icon: 'error',
                    title: error.response.data.error
                });
            }else if(error.response.data.error === 'Contraseña incorrecta'){
                MySwal.fire({
                    icon: 'error',
                    title: error.response.data.error,
                });
            }
        }
    };
    return (
        <>
            {loading ? <Loading /> : null}
            <div className="content">
                <div className="wrapper">
                    <h2>Login</h2>
                    <form onSubmit={handleSubmit} id="formLogin">
                        <div className="input-box">
                            <input
                                type="text"
                                required
                                name="username"
                                placeholder="Usuario"
                                value={formData.username}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="input-box">
                            <input
                                type="password"
                                required
                                name="password"
                                placeholder="Contraseña"
                                value={formData.password}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="input-box button">
                            <input type="submit" value="Iniciar sesión" />
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}
export default Login;