import React, { useEffect, useState } from "react";
import "../../stylesheets/authorization.css";
import axios from "axios";
import Swal from "sweetalert2";
import { formaterNumero, formatearFecha } from "../../function/format";
import withReactContent from "sweetalert2-react-content";
import Loading from "../Loading.jsx";
import CheckCircle from "@mui/icons-material/CheckCircle";
import RemoveRedEye from "@mui/icons-material/RemoveRedEye";
import CloseIcon from "@mui/icons-material/Close";

import {
  Card,
  Typography,
  CardContent,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  InputLabel,
  IconButton,
} from "@mui/material";

function Authorization() {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [loading, setLoading] = useState(false);
  const [documentos, setDocumentos] = useState([]);
  const [pagina, setPagina] = useState(1);
  const [documentosPorPagina] = useState(5);
  const [busqueda, setBusqueda] = useState("");
  const [detalle, setDetalle] = useState([]);
  const [filtroModal, setFiltroModal] = useState({
    noDoc: "",
    idTipoDoc: "",
    fecha: "",
    usuario: "",
    sucursal: "",
    monto: "",
    comentarios: "",
  });

  let config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "application/json",
    },
  };
  useEffect(() => {
    obtenerDocumentos();
  }, []);
  const obtenerDocumentos = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        "http://api.elhalcon.com.ni:9096/api/Documents/getDocuments",
        config
      );
      setLoading(false);
      setDocumentos(response.data);
    } catch (error) {
      console.error("Error al obtener los datos:", error);
      if (
        (error.response && error.response.data.message === "Unauthorized") ||
        error.message === "Network Error" ||
        error.message === "Request failed with status code 429"
      ) {
        localStorage.removeItem("token");
        window.location.href = "/";
      }
    }
  };

  const mostrarTarjetas = () => {
    const indiceInicio = (pagina - 1) * documentosPorPagina;
    const indiceFin = indiceInicio + documentosPorPagina;
    const datosFiltrados = documentos.filter((documento) => {
      const textoTarjeta = `${documento.noDoc} ${documento.fecha} ${documento.usuario} ${documento.sucursal} ${documento.total} ${documento.comentarios}`;
      return textoTarjeta.toLowerCase().includes(busqueda.toLowerCase());
    });

    return datosFiltrados
      .slice(indiceInicio, indiceFin)
      .map((documento, index) => (
        <Grid item xs={12} md={4} lg={4} key={index}>
          <Card className="target" style={{ backgroundColor: "#eee" }}>
            <CardContent>
              <div className="tarj">
                <Typography gutterBottom variant="h6" component="div">
                  {" "}
                  <b>No Documento:</b> <b>{documento.noDoc}</b>
                </Typography>
                <p style={{ marginBottom: "4px" }}>
                  <b>Fecha:</b> {formatearFecha(documento.fecha)}
                </p>
                <p style={{ marginBottom: "4px" }}>
                  <b>Usuario:</b> {documento.usuario}
                </p>
                <p style={{ marginBottom: "4px" }}>
                  <b>Sucursal:</b> {documento.sucursal}
                </p>
                <p style={{ marginBottom: "4px" }}>
                  <b>Monto:</b> {formaterNumero(documento.total)}
                </p>
                <p style={{ marginBottom: "4px" }}>
                  <b>Comentarios:</b> {documento.comentarios}
                </p>
              </div>
              <div className="button-sta">
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginRight: "5px" }}
                  data-id={`${documento.noDoc} ${documento.idTipoDoc}`}
                  onClick={() => {
                    autorizarSalida(documento);
                  }}
                  startIcon={<CheckCircle />}
                >
                  Autorizar
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  sx={{
                    backgroundColor: "#333",
                    "&:hover": {
                      backgroundColor: "#444",
                    },
                  }}
                  data-id={`${documento.noDoc} ${documento.idTipoDoc}`}
                  data-bs-target="#salidasModal"
                  onClick={() => {
                    detalleDocumento(documento);
                    handleOpen();
                  }}
                  startIcon={<RemoveRedEye />}
                >
                  Detalle
                </Button>
              </div>
            </CardContent>
          </Card>
        </Grid>
      ));
  };
  /*Datos del Modal*/
  const detalleDocumento = async (documento) => {
    try {
      setLoading(true);
      const detalle = await axios.get(
        "http://api.elhalcon.com.ni:9096/api/Documents/getDocuments/",
        config
      );
      const filtrado = detalle.data.filter((elemento) => {
        return (
          elemento.noDoc === documento.noDoc &&
          elemento.idTipoDoc === documento.idTipoDoc
        );
      });
      if (filtrado.length > 0) {
        const detalleData = filtrado[0];
        setFiltroModal({
          noDoc: detalleData.noDoc,
          idTipoDoc: detalleData.idTipoDoc,
          fecha: detalleData.fecha,
          usuario: detalleData.usuario,
          sucursal: detalleData.sucursal,
          monto: detalleData.total,
          comentarios: detalleData.comentarios,
        });
      }
      let url =
        "http://api.elhalcon.com.ni:9096/api/Documents/getDocumentDetail/";
      let data = {
        noDoc: documento.noDoc,
        idTipoDoc: documento.idTipoDoc,
      };
      console.log(data);
      const detalleProductos = await axios.post(url, data, {
        headers: config.headers,
      });

      setDetalle(detalleProductos.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error.response && error.response.data.message === "Unauthorized") {
        console.log(error.response.data.message);
      }
    }
  };

  const mostrarDetalleModal = () => {
    const style = {
      zIndex: 10,
    };
    return (
      <>
        <Dialog
          open={open}
          onClose={handleClose}
          maxWidth="md" // Cambiado a "md" para un tamaño mediano
          fullWidth
          style={style}
        >
          <DialogTitle
            style={{
              position: "relative",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            Detalle de las Salidas
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleClose} // Cierra el diálogo al hacer clic en el botón
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <form id="detalle_salidas">
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={6}>
                      <input
                        type="hidden"
                        className=""
                        id="detalle_idTipoDoc"
                        name="detalle_idTipoDoc"
                      />
                      <div className="form-group">
                        <label htmlFor="detalle_fecha">Fecha</label>
                        <input
                          type="text"
                          readOnly
                          className="modal-input"
                          id="detalle_fecha"
                          name="detalle_fecha"
                          value={formatearFecha(filtroModal.fecha)}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <div className="form-group">
                        <label htmlFor="detalle_fecha">Fecha</label>
                        <input
                          type="text"
                          readOnly
                          className="modal-input"
                          id="detalle_fecha"
                          name="detalle_fecha"
                          value={formatearFecha(filtroModal.fecha)}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <div className="form-group">
                        <label htmlFor="detalle_usuario">Usuario</label>
                        <input
                          type="text"
                          readOnly
                          className="modal-input"
                          id="detalle_usuario"
                          name="detalle_usuario"
                          value={filtroModal.usuario}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <div className="form-group">
                        <label htmlFor="detalle_sucursal">Sucursal</label>
                        <input
                          type="text"
                          readOnly
                          className="modal-input"
                          id="detalle_sucursal"
                          name="detalle_sucursal"
                          value={filtroModal.sucursal}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div className="form-group">
                        <label htmlFor="detalle_monto">Monto</label>
                        <input
                          type="text"
                          readOnly
                          className="modal-input"
                          id="detalle_monto"
                          name="detalle_monto"
                          value={formaterNumero(filtroModal.monto)}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div className="form-group">
                        <label htmlFor="detalle_comentario">Comentarios</label>
                        <textarea
                          style={{ resize: "none" }}
                          type="text"
                          readOnly
                          className="modal-input"
                          id="detalle_comentario"
                          name="detalle_comentario"
                          value={filtroModal.comentarios}
                        ></textarea>
                      </div>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
              <Grid item xs={12}>
                <div className="contenedor-table">
                  <Table id="document-detail-table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Código</TableCell>
                        <TableCell>Descripción</TableCell>
                        <TableCell>Bodega</TableCell>
                        <TableCell>Cantidad</TableCell>
                        <TableCell>Costo</TableCell>
                        <TableCell>Costo Total</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {detalle.map((elemento) => (
                        <TableRow key={elemento.codigo} className="tr">
                          <TableCell>{elemento.codigo}</TableCell>
                          <TableCell>{elemento.descripcion}</TableCell>
                          <TableCell>{elemento.bodega}</TableCell>
                          <TableCell>{elemento.cantidad}</TableCell>
                          <TableCell>
                            {formaterNumero(elemento.costo)}
                          </TableCell>
                          <TableCell>
                            {formaterNumero(elemento.importe)}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              onClick={() => autorizarSalida(filtroModal)}
              startIcon={<CheckCircle />}
            >
              Autorizar
            </Button>
            <Button
              variant="outlined"
              onClick={handleClose}
              startIcon={<CloseIcon />}
            >
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  const autorizarSalida = async (documento) => {
    const MySwal = withReactContent(Swal);

    const customSwalClass = {
      popup: "your-custom-swal-class",
    };

    MySwal.fire({
      title: "¿Quieres Autorizar la salida?",
      text: "¡Click, si estás seguro!",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "¡Sí, Autorizar!",
      cancelButtonText: "Cancelar",
      customClass: customSwalClass,
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        const id = `${documento.noDoc} ${documento.idTipoDoc}`;
        const noDoc = id.split(" ")[0];
        const idTipoDoc = id.split(" ")[1];
        let data = {
          noDoc: noDoc,
          idTipoDoc: idTipoDoc,
        };
        try {
          const response = await axios.post(
            "http://api.elhalcon.com.ni:9096/api/Documents/ApplyDocument/",
            data,
            { headers: config.headers }
          );
          obtenerDocumentos();
          MySwal.fire("¡Hecho!", response.data.message, "success");

          handleClose();
          setLoading(false);
        } catch (error) {
          console.error("Error al enviar la solicitud POST:", error);
        }
      }
    });
  };

  const handlePaginaAnterior = () => {
    if (pagina > 1) {
      setPagina(pagina - 1);
    }
  };

  const handlePaginaSiguiente = () => {
    const totalPaginas = Math.ceil(
      documentos.filter((documento) => {
        const textoTarjeta = `${documento.noDoc} ${documento.fecha} ${documento.usuario} ${documento.sucursal} ${documento.total} ${documento.comentarios}`;
        return textoTarjeta.toLowerCase().includes(busqueda.toLowerCase());
      }).length / documentosPorPagina
    );

    if (pagina < totalPaginas) {
      setPagina(pagina + 1);
    }
  };

  const handleBuscar = (e) => {
    setBusqueda(e.target.value);
    setPagina(1);
  };

  return (
    <>
      {loading ? <Loading /> : null}
      <Grid container spacing={2} style={{ marginBottom: "25px" }}>
        <Grid item xs={12} className="contenido_titulo">
          Autorización de salidas de inventario
        </Grid>
        <Grid item xs={12}>
          <div className="contenido_grupo">
            <InputLabel htmlFor="buscar">Buscar: </InputLabel>
            <input
              type="text"
              className="buscar"
              id="buscar"
              placeholder="Buscar"
              value={busqueda}
              onChange={handleBuscar}
            />
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        {mostrarTarjetas()}
        <Grid item xs={12}>
          <div id="paginacion">
            <Button id="pagina-anterior" onClick={handlePaginaAnterior}>
              {" "}
              Atrás
            </Button>
            <span id="numero-pagina">
              {" "}
              Página {pagina} de{" "}
              {Math.ceil(
                documentos.filter((documento) => {
                  const textoTarjeta = `${documento.noDoc} ${documento.fecha} ${documento.usuario} ${documento.sucursal} ${documento.total} ${documento.comentarios}`;
                  return textoTarjeta
                    .toLowerCase()
                    .includes(busqueda.toLowerCase());
                }).length / documentosPorPagina
              )}
            </span>
            <Button id="pagina-siguiente" onClick={handlePaginaSiguiente}>
              {" "}
              Siguiente
            </Button>
          </div>
        </Grid>
      </Grid>
      {mostrarDetalleModal()}
    </>
  );
}
export default Authorization;
