import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

function tokenExisteEnLocalStorage() {
   const token = localStorage.getItem('token');
   return token !== null;
}
const IsLoggedin = ({
   redirectPath = '/'
}) => {
   if (tokenExisteEnLocalStorage()) {
      return <Outlet/>;
   } else {
      return <Navigate to={redirectPath} replace/>;
   }
}

const IsNoLoggedin = ({
   redirectPath = '/administrator'
}) => {
   if (tokenExisteEnLocalStorage()) {
      return <Navigate to={redirectPath} replace/>;
   } else {
      return <Outlet/>; 
   }
}
export {IsLoggedin, IsNoLoggedin};